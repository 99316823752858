.breadcrumb {
  display: flex;

  &__left, &__right {
    flex: 1;
    list-style: none;
  }

  &__left {
    text-align: center;

    @media (--small-desktop) {
      text-align: left;
      flex-basis: 50%;
    }

  }

  &__right {
    padding-left: 0;
    display: none;
    text-align: right;
    color: var(--yellow);

    @media (--small-desktop) {
      display: block;
    }

  }

  &__title, &__action {
    margin: 0;
    padding: 0 calc(var(--gap) / 2);
    display: inline-block;
    background: var(--dark-gray);
    list-style: none;

    @media (--small-desktop) {
      padding: 0 calc(var(--gap) / 2) 0 0;
    }

    li {
      margin: 0;
      padding: 0;
      list-style: none;
      font-family: var(--din-bold);
      font-size: 21px;
      line-height: 1.375;
      letter-spacing: var(--letter-space);
      text-transform: uppercase;
    }

  }

  &__title {

    li {
      display: inline-block;

      &::before {
        margin: 0 5px 0 3px;
        content: '/';
        display: none;
        color: var(--yellow);
      }

      &:last-of-type {

        &::before {
          display: inline-block;
        }

      }

      &:only-of-type {

        &::before {
          display: none;
        }

      }

    }

  }

  &__action {
    padding-right: 0;
    padding-left: calc(var(--gap) / 2);

    a {
      text-transform: uppercase !important;
    }
  }

  a {
    color: var(--white);
    text-decoration: none;

    &:hover {
      color: var(--red);
      text-decoration: underline;
    }
  }

}
