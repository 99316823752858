footer {
  margin: var(--gap) 0;
  padding: 0 0 var(--base-size) 0;
  display: flex;
}

.copyright {
  padding: 0 0 var(--base-size) 0;
  width: 100%;
  display: inline-block;
  background: var(--dark-gray);
  font-size: 14px;
  line-height: normal;
  letter-spacing: var(--letter-space);
  text-transform: uppercase;
  text-align: center;

  @media (--small-desktop) {
    padding: 0 10px var(--base-size) 0;
    width: auto;
    text-align: left;
  }

  span {
    display: block;

    @media (--small-desktop) {
      display: inline;
    }

  }

}