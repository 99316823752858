a.tile {
  text-decoration: none;

  &:hover {
    color: inherit;
  }

}

.tile {
  border-left: 1px solid var(--white);

  &:hover {
    cursor: pointer;

    .tile__image, .tile__inner {

      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 1;
      }

    }

  }

  a {
    text-decoration: none;
    text-transform: inherit;

    &:hover {
      color: var(--white);
    }

  }

  &--noted {

    &:hover {
      cursor: inherit;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: var(--red);
      }

    }

  }

  &__topbar {
    width: calc(100% + var(--gap));
    background: var(--white);
    height: var(--gap);
    margin-left: -24px;
    display: flex;
    justify-content: flex-end;

    &__svg {
      width: var(--gap);
      height: var(--gap);
      background: var(--dark-gray);
      border-left: 2px solid var(--dark-gray);
    }
  }

  &__inner {
    margin: 0 0 0 var(--gap);
    padding: 0 0 var(--gap) 0;
    height: 100%;
  }

  &__image {
    /* margin-bottom: var(--gap); */
    position: relative;
    background: var(--black);

    &::before {
      content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--gradient-red);
			opacity: 0;
			transition: opacity .25s ease;
    }

    svg {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      z-index: 1;
    }

  }

  &__icon {

    &::after {
      content: '';
      background:
        url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="64" height="46" viewBox="0 0 64 46"><path fill="%23FFF" fill-rule="evenodd" d="M48 38.627c-8.836 8.836-23.164 8.836-32 0l-16-16 16-16c8.836-8.836 23.164-8.836 32 0l16 16-16 16zm-16-2.279c7.578 0 13.721-6.143 13.721-13.72 0-7.579-6.143-13.722-13.72-13.722-7.579 0-13.722 6.143-13.722 13.721S24.422 36.348 32 36.348zm5.86-13.72a5.86 5.86 0 1 1-11.721 0 5.86 5.86 0 0 1 11.722 0z"/></svg>')
        no-repeat
        center
        center
      ;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
      width: 100%;
			height: 100%;
      opacity: 0;
      transition: opacity .25s ease;
    }

  }

  &__content {
    padding: var(--gap) 0;
    background: var(--dark-gray);
  }

  &__meta {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: var(--letter-space);
  }

  &__title {
    margin-top: 5px;
    line-height: 24px;
    letter-spacing: var(--letter-space);
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.4;
  }

  &--modal {

    .tile__inner {
      position: relative;

      &::before {
        display: block;
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFF" fill-rule="evenodd" d="M0 0h24v24H0V0zm6 6v12h12V6H6zm2 2h8v8H8V8z"/></svg>');
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
			  transition: opacity .25s ease;
      }
    }
  }

  &--external {

    .tile__inner {
      position: relative;

      &::before {
        display: block;
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23FFF" fill-rule="evenodd" d="M18 8v10h-1.997V9.4l-9.6 9.599-1.411-1.412L14.578 8H6.02V6H18v2zM0 24h24V0H0v24z"/></svg>');
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
			  transition: opacity .25s ease;
      }

    }

  }

}
