@custom-media --laptop (min-width: 1068px);
@custom-media --small-desktop (min-width: 768px);
@custom-media --small-phone (min-width: 321px);

.grid {
  display: grid;

  &__outer {
    grid-template-columns: 1fr;
    grid-gap: var(--gap);

    @media (--small-desktop) {
      grid-template-columns: 1fr 1fr;
    }

    @media (--laptop) {
      grid-template-columns: 2.049fr 1fr;
    }

    &--full {
      grid-template-columns: 1fr;
      grid-gap: var(--gap);

      .grid__tiles {
        grid-template-columns: 1fr;
        grid-gap: var(--gap);

        @media (--small-desktop) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        @media (--laptop) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

      }

    }

  }

  &__tiles {
    grid-template-columns: 1fr;
    grid-gap: var(--gap);

    @media (--small-phone) {
      grid-template-columns: 1fr 1fr;
    }

    @media (--laptop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__header, &__footer {
    margin-bottom: var(--gap);
  }

  &__footer {

    .breadcrumb__left {
      display: none;

      @media (--small-desktop) {
        display: block;
        flex-basis: 60%;
      }

    }

    .breadcrumb__right {
      display: block;
      text-align: center;

      @media (--small-desktop) {
        text-align: right;
      }

    }

    .breadcrumb__action {
      padding-right: calc(var(--gap) / 2);

      @media (--small-desktop) {
        padding-right: 0;
      }

    }

  }

  &--two-col {
    grid-template-columns: 1fr;

    @media (--small-phone) {
      grid-template-columns: 1fr 1fr;
    }

  }

  &--two-offset-col {
    grid-template-columns: 1fr;

    @media (--small-desktop) {
      grid-template-columns: 1fr 2fr;
    }

  }

  &--three-col {
    grid-template-columns: 1fr;

    @media (--small-desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__padded {

    &--right {

      @media (--small-desktop) {
        padding-right: var(--gap);
      }
    }

    &--left {

      @media (--small-desktop) {
        padding-left: var(--gap);
      }
    }

  }

  &__bordered {

    @media (--small-desktop) {
      border-left: 1px solid var(--white);
    }
  }

}
