a.btn {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &--secondary {
    padding: 20px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: var(--letter-space);
    background: var(--red);
    color: var(--white);
    border-color: var(--red);

    &:hover {
      background: var(--white);
      color: var(--red);
      border-color: var(--red);
    }

  }

  &--white {
    padding: 20px;
    display: inline-block;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: var(--letter-space);
    background: var(--white);
    color: var(--dark-gray);
    border-color: var(--white);

    &:hover {
      background: var(--red);
      color: var(--white);
      border-color: var(--red);
    }

  }

  &--full {
    display: block;
  }

  &--medium {
    padding: 10px var(--gap);
  }

  &--small {
    padding: 0 10px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: var(--letter-space);
  }

  &--has-border {
    border-width: 1px;
    border-style: solid;
  }
}
