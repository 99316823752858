.search {
  margin: 0 0 var(--gap) 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--white);

  @media (--small-desktop) {
    flex-direction: row;
  }

  &__text {
    margin-bottom: 2px;
    padding: 10px 12px;
    flex: 1;
    background: var(--white);
    color: var(--dark-gray);
    text-align: left;
    text-transform: uppercase;
    letter-spacing: var(--letter-space);

    @media (--small-desktop) {
      margin: 0;
      padding: 20px;
      text-align: center;
      max-width: 245px;
    }

  }

  &__input {
    flex: 1 1 60%;
    display: flex;
    position: relative;

    input {
      margin: 0;
      padding: 20px 22px;
      width: 100%;
      border: 2px solid var(--dark-gray);
      border-top: 0;
      border-left: 0;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.2);
      color: var(--white);
      font-size: var(--base-size);
      line-height: var(--base-line-height);
      text-transform: uppercase;
      letter-spacing: var(--letter-space);
      outline: 0;

      &:focus {
        background: rgba(255, 255, 255, 0.3);
      }

      @media (--small-desktop) {
        border-left: 2px solid var(--dark-gray);
      }
    }

  }

  &__results {
    position: absolute;
    padding: 20px 22px;
    width: calc(100% - 64px);
    border: 2px solid var(--dark-gray);
    border-top: 0;
    border-left: 0;
    background: var(--white);
    color: var(--dark-gray);
    text-transform: uppercase;
    letter-spacing: var(--letter-space);

    @media (--small-desktop) {
      border-left: 2px solid var(--dark-gray);
    }

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

  }

  &__button {
    padding: 20px;
    min-width: 64px;
    flex: 1;
    background: var(--white);
    color: var(--dark-gray);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: var(--letter-space);

    &:hover {
      cursor: pointer;
      background: var(--red);
      color: var(--white);

      svg {

        path {
          fill: var(--white);
        }

      }

    }

    svg {
      vertical-align: middle;
    }
  }

}