@custom-media --small-desktop (min-width: 768px);

header {
  margin: 0 0 var(--gap) 0;
  position: relative;
  background: var(--white);
}

.header {

  &__inner {
    padding: var(--gap) 48px var(--gap) var(--gap);
  }

  &__link {
    display: flex;
    text-decoration: none;
    max-width: 620px;

    &:hover,
    &:visited {
      text-decoration: none;
    }
  }

  &__icon {
    margin-right: 24px;
    position: relative;
  }

  &__astronaut {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__roger {
    @media (--small-desktop) {
      margin-top: 5px;
    }
  }

  &__tagline {
    margin: 10px 0 0 0;
    color: var(--dark-gray);
    letter-spacing: var(--letter-space);
    text-transform: uppercase;
    display: none;

    @media (--small-desktop) {
      display: block;
    }

  }

}
