@custom-media --small-desktop (min-width: 768px);

.color__picker {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  bottom: 0;

  @media (--small-desktop) {
    flex-direction: column;
  }

  a {
    width: var(--gap);
    height: var(--gap);
    position: relative;
    display: block;
    background: var(--gray);
    text-indent: -9999px;

    &::before {
      content: '';
      width: calc(var(--gap) / 2);
      height: calc(var(--gap) / 2);
      display: block;
      position: absolute;
      top: 6px;
      left: 6px;
      background: var(--dark-gray);
      border-radius: 50%;
    }

    &:nth-child(2n) {

      &::before {
        background: var(--white);
      }

    }

  }

  img {
    transform: rotate(-90deg);

    @media (--small-desktop) {
      transform: rotate(0deg);
    }

  }

}
