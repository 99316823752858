@import "base/_fonts.pcss";
@import "base/_variables.pcss";
@import "base/_main.pcss";
@import "base/_typography.pcss";
@import "base/_grid.pcss";

/* components */
@import "components/_animations.pcss";
@import "components/_dots.pcss";
@import "components/_header.pcss";
@import "components/_breadcrumbs.pcss";
@import "components/_separator.pcss";
@import "components/_tile.pcss";
@import "components/_color__picker.pcss";
@import "components/_buttons.pcss";
@import "components/_image.pcss";
@import "components/_video.pcss";
@import "components/_blocks.pcss";
@import "components/_download.pcss";
@import "components/_404.pcss";
@import "components/_footer.pcss";
@import "components/_search.pcss";
@import "components/_pagination.pcss";
@import "components/_modal.pcss";

@import "base/_helpers.pcss";

.theme--light {
  --black: #1f1f1f;
  --dark-gray: #ffffff;
  --white: #424242;
  --red: #357cf9;
  --blue: #f9572a;
  --yellow: #fcc300;
  --gray: #dadada;

  --dot-pattern-dark:
    linear-gradient(90deg, #fff 2px, transparent 1%) center,
		linear-gradient(#fff 2px, transparent 1%) center, #424242;

  --gradient-red: radial-gradient(circle at 50% 50%, #649cff, #145ad5);

  a.btn--secondary,
  a.btn--secondary:hover,
  .search__button:hover {
    color: var(--dark-gray);
  }

  .color__picker a::before {
    --dark-gray: #424242;
  }

  .color__picker a:nth-child(2n)::before {
    --white: #ffffff;
  }

  .tile__image {
    background: #333;
  }

  .tile__topbar__svg {
    path {
      fill: var(--white);
    }
  }

  .svg-box-fix,
  .svg-line-fix {
    fill: var(--red);
  }

}
