#background {
	display: none;
}

#transmission #wave1, #transmission #wave2, #transmission #wave3, #transmission #wave4 {
	opacity: 0;
}

#noted #wave-1, #noted #wave-2, #noted #wave-3, #noted #wave-4 {
	animation: blink 1s step-start infinite;
}

#noted #star {
	animation: blink-20 1s step-start infinite;
}

#noted #dim-stars {
	animation: blink-20 1s step-start infinite;
}

#info #arm-down {
	animation: blink 1s step-end infinite;
	opacity: 0.4;
}

#info #arm-waving {
	animation: blink 1s step-start infinite;
}

#info #me {
	animation: blink-40 1s step-start infinite;
}

#info #earth {
	animation: blink 1s step-end infinite;
}

#info #earth-highlighted {
	animation: blink 1s step-start infinite;
}

#transmission #right-panel .cls-12 {
	animation: blink 1s step-start infinite;
}

#transmission #left-panel .cls-12 {
	animation: blink 1s step-end infinite;
}

#transmission #dot {
	animation: blink 1s step-end infinite;
}

#transmission #wave1 {
	animation: fadeIn 2.5s ease infinite;
}

#transmission #wave2 {
	animation: fadeIn 2.5s ease 0.15s infinite;
}

#transmission #wave3 {
	animation: fadeIn 2.5s ease 0.2s infinite;
}

#transmission #wave4 {
	animation: fadeIn 2.5s ease 0.25s infinite;
}

#inventory #item1, #inventory #item5, #inventory #item9, #inventory #item13, #inventory #item17 {
	animation: fadeIn 2s ease infinite;
}

#inventory #item2, #inventory #item6, #inventory #item10, #inventory #item14, #inventory #item18 {
	animation: fadeIn 2s ease 1.5s infinite;
}

#inventory #item3, #inventory #item7, #inventory #item11, #inventory #item15, #inventory #item19 {
	animation: fadeIn 2s ease 3s infinite;
}

#inventory #item4, #inventory #item8, #inventory #item12, #inventory #item16 {
	animation: fadeIn 2s ease 4.5s infinite;
}

#library #front-book-1, #library #front-book-8, #library #front-book-9 {
	animation: fadeIn-50 2s ease infinite;
}

#library #front-book-2, #library #front-book-7, #library #front-book-10 {
	animation: fadeIn 2s ease 1.5s infinite;
}

#library #front-book-3, #library #front-book-6, #library #front-book-11 {
	animation: fadeIn 2s ease 3s infinite;
}

#library #front-book-4, #library #front-book-5, #library #front-book-12 {
	animation: fadeIn 2s ease 4.5s infinite;
}

#collected #target1, #collected #grip-closed {
	animation: blink 1s step-start infinite;
}

#collected #target2, #collected #grip-open {
	animation: blink 1s step-end infinite;
}

#collected #item1, #collected #item5, #collected #item9, #collected #item13 {
	animation: fadeIn 2s ease infinite;
}

#collected #item2, #collected #item6, #collected #item10 {
	animation: fadeIn 2s ease 1.5s infinite;
}

#collected #item3, #collected #item7, #collected #item11 {
	animation: fadeIn 2s ease 3s infinite;
}

#collected #item4, #collected #item8, #collected #item12 {
	animation: fadeIn 2s ease 4.5s infinite;
}

/*#photographed #photo-area {
	animation: blink .75s step-start infinite;
}*/

#photographed #crosshairs {
	animation: blink 1s step-start infinite;
}

#photographed #viewfinder-2 {
	animation: blink 1s step-start infinite;
}

#photographed #photo-capture {
	animation: blink 1s step-end 1s infinite;
}

#travels #stripe-1, #travels #stripe-5 {
	animation: blink 1.5s ease .25s infinite;
}

#travels #stripe-2, #travels #stripe-4 {
	animation: blink 1.5s ease infinite;
}

#fellows #info-1, #fellows #info-2, #fellows #info-2-text {
	animation: blink 1.5s step-start infinite;
}

#fellows #info-3 {
	animation: blink 1s step-end infinite;
}

#fellows #info-4 {
	animation: blink 1s step-start infinite;
}

#fellows #info-5 {
	animation: blink 1s step-end infinite;
}

#services #buttons-1, #services #buttons-3 {
	animation: blink 1s step-end infinite;
}

#services #buttons-2 {
	animation: blink 1s step-start infinite;
}

#services #screen-1, #services #screen-5 {
	animation: fadeIn-20 2s ease infinite;
}

#services #screen-2, #services #screen-6 {
	animation: fadeIn-20 2s ease .5s infinite;
}

#services #screen-3, #services #screen-7 {
	animation: fadeIn-20 2s ease 1s infinite;
}

#services #screen-4, #services #screen-8 {
	animation: fadeIn-20 2s ease 1.5s infinite;
}

#clientwork #star1, #clientwork #star4, #clientwork #star7 {
	animation: fadeIn-20 2s ease infinite;
}

#clientwork #star2, #clientwork #star5 {
	animation: fadeIn-20 2s ease .5s infinite;
}

#clientwork #star3, #clientwork #star6 {
	animation: fadeIn-20 2s ease 1s infinite;
}

#clientwork #panel-1 .cls-11 {
	animation: blink 1s step-start infinite;
}

#clientwork #panel-2 .cls-11 {
	animation: blink 1s step-end infinite;
}

#side #path-a, #side #objects-a {
	animation: fadeIn-20 1.5s ease infinite;
}

#side #path-b, #side #objects-b {
	animation: fadeIn-20 1.5s ease .25s infinite;
}

#side #path-c, #side #objects-c {
	animation: fadeIn-20 1.5s ease .5s infinite;
}

#side #path-d, #side #objects-d {
	animation: fadeIn-20 1.5s ease .75s infinite;
}

#shop #star1 {
	animation: fadeIn-10 2s ease .6s infinite;
}

#shop #star2 {
	animation: fadeIn-10 2s ease infinite;
}

#shop #star3 {
	animation: fadeIn-10 2s ease .1s infinite;
}

#shop #star4 {
	animation: fadeIn-10 2s ease .2s infinite;
}

#shop #star5 {
	animation: fadeIn-10 2s ease .4s infinite;
}

#shop #star6 {
	animation: fadeIn-10 2s ease .35s infinite;
}

#shop #path4 {
	animation: fadeIn-10 1.5s ease infinite;
}

#shop #path3 {
	animation: fadeIn-10 1.5s ease .25s infinite;
}

#shop #path2 {
	animation: fadeIn-10 1.5s ease .5s infinite;
}

#shop #path1 {
	animation: fadeIn-10 1.5s ease .75s infinite;
}

#shop #dome1 .animate {
	animation: fadeIn-20 2s ease infinite;
}

#shop #dome2 .animate {
	animation: fadeIn-20 2s ease .25s infinite;
}

#shop #dome3 .animate {
	animation: fadeIn-20 2s ease .5s infinite;
}

#shop #dome4 .animate {
	animation: fadeIn-20 2s ease .75s infinite;
}

#robot #button1, #robot #button4 {
	animation: blink 1s step-end infinite;
}

#robot #button2, #robot #button5 {
	animation: blink 2s step-start infinite;
}

#robot #signal-1, #robot #signal-4 {
	animation: fadeIn 1s ease infinite;
}

#robot #signal-2, #robot #signal-5 {
	animation: fadeIn 1s ease .15s infinite;
}

#robot #signal-3, #robot #signal-6 {
	animation: fadeIn 1s ease .25s infinite;
}

#robot #button3 {
	animation: blink 1s step-end infinite;
}

#robot #eye-left {
	animation: blink 1s step-end infinite;
}

#robot #eye-right {
	animation: blink 1.5s step-end infinite;
}

#robot #right {
	animation: fadeIn-10 1.5s ease infinite;
}

#robot #back {
	animation: fadeIn-10 1.5s ease .2s infinite;
}

#robot #left {
	animation: fadeIn-10 1.5s ease .4s infinite;
}

#robot #light-1, #robot #light-4 {
	animation: blink 1s step-end infinite;
}

#robot #light-2, #robot #light-5 {
	animation: blink 1s step-start infinite;
}

#robot #light-3, #robot #light-6 {
	animation: blink 2s step-end infinite;
}

#robot #cassette {
	animation: blink 2s step-end infinite;
}

#illustration #drawing-1 {
	animation: blink 1s step-start infinite;
}

#illustration #drawing-2 {
	animation: blink 1s step-end infinite;
}

#illustration #drawing-3 {
	display: none;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes fadeIn-10 {
	0% { opacity: .1; }
	100% { opacity: 1; }
}

@keyframes fadeIn-20 {
	0% { opacity: .2; }
	100% { opacity: 1; }
}

@keyframes fadeIn-50 {
	0% { opacity: .5; }
	100% { opacity: 1; }
}

@keyframes blink {
	50% { opacity: 0; }
}

@keyframes blink-20 {
	50% { opacity: .2; }
}

@keyframes blink-40 {
	50% { opacity: .4; }
}
