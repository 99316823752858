@import-normalize;

*,
*:after,
*:before {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	min-height: 100%;
}

body {
  margin: var(--gap);
  padding: 0;
  font-family: var(--din);
  font-size: var(--base-size);
  line-height: var(--base-line-height);
  background: var(--dark-gray);
  color: var(--white);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.grid__padded--right {

  figure:nth-child(1) {
      margin-top: 0;
    }

}

.content {
  margin: var(--gap) 0 0;

  &--middle {

    figure:nth-child(1) {
      margin-top: 0;
    }

  }

  &--left {

    div:nth-child(1) {
      margin-top: 0;
      margin-bottom: 0;
    }

  }

  &--right {
    div:nth-child(1), figure:nth-child(1) {
      margin-top: 0;
    }
  }

  hr {
    margin: calc(var(--gap) * 2) 0;
    border-top: 1px dotted var(--white);
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    opacity: 0.3;
  }

}