.error {

  &__page {
    display: flex;

    &__inner {
      margin: 200px auto;
      padding: var(--gap);
      background: var(--dark-gray);
      text-align: center;
      letter-spacing: var(--letter-space);
      text-transform: uppercase;
    }

  }

}