.image {
  width: 100%;
  position: relative;

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(var(--gap) * 2);

    &__cell {
      flex: 0 1 100%;
      position: relative;
      overflow: hidden;

      @media (--small-desktop) {
        flex-basis: calc(50% - 12px);
      }

      @media (--laptop) {
        flex-basis: calc(50% - 12px);
      }

      &:nth-child(2n) {
        margin: var(--gap) 0 0;

        @media (--small-desktop) {
          margin: 0 0 0 var(--gap);
        }

        @media (--laptop) {
          margin: 0 0 0 var(--gap);
        }

      }

      &:nth-child(3), &:nth-child(4),
      &:nth-child(5), &:nth-child(6),
      &:nth-child(7), &:nth-child(8),
      &:nth-child(9), &:nth-child(10),
      &:nth-child(11), &:nth-child(12),
      &:nth-child(13), &:nth-child(14),
      &:nth-child(15), &:nth-child(16),
      &:nth-child(17), &:nth-child(18),
      &:nth-child(19), &:nth-child(20),
      &:nth-child(21), &:nth-child(22),
      &:nth-child(23), &:nth-child(24),
      &:nth-child(25), &:nth-child(26),
      &:nth-child(27), &:nth-child(28),
      &:nth-child(29), &:nth-child(30),
      &:nth-child(31), &:nth-child(32),
      &:nth-child(33), &:nth-child(34),
      &:nth-child(35), &:nth-child(36),
      &:nth-child(37), &:nth-child(38),
      &:nth-child(39), &:nth-child(40),
      &:nth-child(41), &:nth-child(42) {
        margin-top: var(--gap);
      }

      img {
        margin: 0;
      }

      .image__caption {
        bottom: 0;
      }

      .image__caption__opener {
        bottom: 8px;
      }

    }

  }

  &__caption {
    padding: var(--gap);
    position: absolute;
    left: 0;
    right: 0;
    bottom: var(--gap);
    background: var(--white);
    color: var(--dark-gray);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: -300px;
    transition: margin 0.2s ease-in-out;

    &.is-open {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      font-size: 14px;
    }

    a {
      color: inherit;
    }

    &__closer {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
    }

    &__opener {
      display: flex;
      position: absolute;
      bottom: calc(var(--gap) + 8px);
      right: 8px;
      cursor: pointer;
    }

  }

}

figure {
  margin: calc(var(--gap) * 2) 0;
  position: relative;
  overflow: hidden;

  .image__caption__opener {
    bottom: 12px;
  }

  .image__caption.is-open {
    margin-bottom: -24px;
  }
}

/* for dev only */
.image img {
  width: 100%;
}
