.has-top-border {
  border-top: 1px solid var(--white);
}

.only--mobile {

  display: block;

    @media (--small-desktop) {
      display: none;
    }

}

.m-top-none {
  margin-top: 0;
}