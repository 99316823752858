h1 {
  margin: 0 0 16px;
  font-family: var(--din-bold);
  font-size: 21px;
  line-height: var(--base-line-height);
  letter-spacing: var(--letter-space);
  text-transform: uppercase;

  &.small {
    font-size: var(--base-size);
    line-height: var(--base-line-height);
  }
}

h2 {
  margin: 0 0 16px;
  font-family: var(--din-bold);
  font-size: 21px;
  line-height: 1.29;
}

h3 {
  margin: 0 0 16px;
  font-family: var(--din-bold);
  font-size: var(--base-size);
  line-height: var(--base-line-height);
  letter-spacing: var(--letter-space);
  text-transform: uppercase;
}

h5 {
  font-size: 12px;
  font-family: var(--din);
  font-weight: normal;
  letter-spacing: var(--letter-space);
  text-transform: uppercase;
}

p {
  margin: 0 0 16px;

  &.small {
    font-size: 14px;
  }

  &.xsmall {
    font-size: 12px;
    margin-bottom: 5px;
  }

}

a {
  color: var(--white);
  text-decoration: underline;
  text-transform: uppercase;

  &:visited {
    color: var(--white);
    text-decoration: underline;
  }

  &:hover {
    color: var(--red);
    text-decoration: underline;
  }

}

a.transmission__link {
  text-decoration: none;
  text-transform: uppercase !important;

  &:hover {
    text-decoration: underline;
  }
}

.content a {
  text-transform: none;
}

ul, ol {
  padding-left: 30px;

  li {
    margin: 5px 0;
    list-style: square;
    line-height: var(--base-line-height);
  }

}

ol {

  li {
    list-style: decimal;
  }

}

.no--bullets {
  padding-left: 0;

  li {
    list-style: none;
  }

}

.uppercase, a.uppercase {
  text-transform: uppercase;
  letter-spacing: var(--letter-space);
}

.collected,
.robot-friends,
.mission-vehicle,
.exploration,
.client-work,
.drawing-board,
.side-projects,
.shop {
  h1 {
    text-transform: inherit;
    letter-spacing: 0;
  }
}
