@font-face {
    font-family: "DIN Next LT W01 Regular";
    src: url("../fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot?#iefix");
    src: url("../fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot?#iefix") format("eot"),
      url("../fonts/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"),
      url("../fonts/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff"),
      url("../fonts/126195b4-2fa0-4b95-b5cf-ad9bf10193f0.ttf") format("truetype");
}
@font-face {
    font-family: "DIN Next LT W01 Bold";
    src: url("../fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
    src: url("../fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix") format("eot"),
      url("../fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"),
      url("../fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff"),
      url("../fonts/92ed1479-1d40-4a94-8baf-6abd88b17afa.ttf") format("truetype");
}
