:root {

  --base-size: 16px;
  --base-line-height: 1.5;
  --letter-space: 0.6px;
  --gap: 24px;

  /* colors */
  --black: #1f1f1f;
  --dark-gray: #424242;
  --white: #fff;
  --red: #f9572a;
  --blue: #357cf9;
  --yellow: #fcc300;
  --gray: #dadada;

  /* gradients */
  --gradient-red: radial-gradient(circle at 50% 50%, #ff964d, #ff522c);
  --gradient-blue: radial-gradient(circle at 50% 50%, #649cff, #145ad5);

  /* dot pattern */
  --dot-size: 1px;
  --dot-space: 3px;

  --dot-pattern-dark:
    linear-gradient(90deg, #424242 2px, transparent 1%) center,
		linear-gradient(#424242 2px, transparent 1%) center, rgba(255,255,255,0.4);

  --dot-pattern-light:
    linear-gradient(90deg, #fff 2px, transparent 1%) center,
		linear-gradient(#fff 2px, transparent 1%) center, #424242;

  --dot-pattern-size: var(--dot-space) var(--dot-space);

  /* fonts */
  --din: "DIN Next LT W01 Regular", sans-serif;
  --din-bold: "DIN Next LT W01 Bold", sans-serif;

  /* media query sizes */
  --mobile: 580px;
  --small: 768px;
  --laptop-size: 1140px;
}
