.download {
  margin: 0 0 16px 0;
  display: grid;
  grid-template-columns: 1fr 64px;
  grid-template-rows: 1fr;
  background: var(--white);
  text-decoration: none;

  &:last-of-type {
    margin: 0;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;

    .download__name {
      color: var(--red);
    }

    .download__button {
      background: var(--white);

      svg g {
        fill: var(--red);
      }

    }

  }

  &__name {
    padding: 16px 24px;
    font-family: var(--din-bold);
    text-transform: uppercase;
    color: var(--dark-gray);
    align-self: center;

    span {
      font-family: var(--din);
    }

  }

  &__button {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    max-width: 64px;
    background: var(--red);
  }

}