.separator {
  margin: var(--gap) 0 0;
  display: flex;
  position: relative;
  background: var(--white);

  &__filled {
    background: var(--white);
    min-height: var(--gap);
    display: flex;
    justify-content: flex-end;

    &:nth-child(2n) {
      display: none;

      @media (--small-desktop) {
        display: block;
      }
    }

    .btn {
      margin-top: -1px;
      padding: 0 18px;
    }

  }

  .btn-rss {
    position: absolute;
    right: 0;
    min-width: 64px;
  }

}