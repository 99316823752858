.pagination {
  margin: 0 0 var(--gap) 0;
  display: flex;
  align-items: center;

  @media (--small-desktop) {
    border-top: 1px solid var(--white);
  }

  &__list {
    flex: 1 1 60%;
    text-align: center;
    display: none;

    @media (--small-desktop) {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0 calc(var(--gap) / 1.5);
        padding: 0;
        list-style: none;
        display: inline-block;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &.active {

            &::before {
              content: '[';
              display: inline-block;
              margin-right: 2px;
            }

            &::after {
              content: ']';
              display: inline-block;
              margin-left: 2px;
            }

          }

        }

      }

    }

  }

  &__button {
    flex: 1;

    &:last-of-type {
      align-content: flex-end;
      text-align: right;
    }

    .btn {
      padding: 10px var(--gap);
      min-width: 120px;

      @media (--small-desktop) {
        padding: 20px;
        min-width: 200px;
      }

    }

  }

}