.block {

  &__text, &__price, &__shop__item {
    margin: calc(var(--gap) * 2) 0;
  }

  &__title {
    margin-bottom: calc(var(--gap) * 2);
  }

  &__shop__item {

    h2,
    h3 {
      font-size: 21px;
      line-height: 1.29;
    }

    .btn {
      margin-top: 5px;
    }

  }

  &__price {
    margin-top: calc(var(--gap) * 2) !important;
    &:last-of-type {
      margin-bottom: 0;
    }

    h3 {
      font-size: 21px;
      line-height: 1.29;
    }

    ul {
      padding-left: 15px;

      li {
        font-size: 14px;
      }

    }

    &__buttons {
      margin: var(--gap) 0 0;
      display: flex;

      .btn:nth-child(2n) {
        margin-left: 8px;
      }

    }

  }

  &__downloads {
    margin: calc(var(--gap) * 2) 0;
  }

  &__inner {
    background: var(--dark-gray);

    @media (--small-desktop) {
      margin: 0 0 0 calc(var(--gap) * 2);
      padding: 0 0 0 calc(var(--gap) * 2);
    }

  }

  &__spacer {
    margin: calc(var(--gap) * 2) 0;
    height: var(--gap);
  }

  &__aside {
    margin: 0 0 calc(var(--gap) * 2) 0;
    padding: var(--gap);
    background: var(--white);
    color: var(--dark-gray);

    hr {
      margin: var(--gap) 0;
      border-top: 1px dotted var(--dark-gray);
    }

  }

  &__expander {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    &.is--expanded {
      max-height: 1100px;

      .block__expander__button {

        svg {
          transform: rotate(180deg);
        }

      }

    }

    &__button {
      margin-top: var(--gap);

      svg {

        &:hover {
          cursor: pointer;

            path {
              fill: var(--red);
            }

        }

      }

    }

  }

}
